ul {
  margin: 0;
  padding: 0px;
}

.link {
  color: $primary;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.ng-spinner {
  padding: 12px;
  border-radius: 100%;
  background: #FFFFFFEE;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.06);
}

.btn-secondary {
  background-color: #f3f5f7;
  border: 1px solid #dde2e7;
  color: #6c7293;

  &:disabled {
    background-color: $gray-300;
    border-color: $gray-300;
    cursor: not-allowed
  }
}

.btn-action-primary {
  background-color: $primary;
  color: #fff;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  border-radius: .25rem;
  box-sizing: border-box;
  transition-delay: 0s, 0s, 0s, 0s, 0s;
  transition-duration: .15s, .15s, .15s, .15s, .15s;
  transition-property: color, background-color, border-color, box-shadow, -webkit-box-shadow;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out, ease-in-out;

  &:disabled {
    pointer-events: none;
    background-color: $gray-200;
    color: $gray-700;
  }

  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.btn-action-green {
  background-color: $primary;
  color: #fff;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  border-radius: .25rem;
  box-sizing: border-box;
  transition-delay: 0s, 0s, 0s, 0s, 0s;
  transition-duration: .15s, .15s, .15s, .15s, .15s;
  transition-property: color, background-color, border-color, box-shadow, -webkit-box-shadow;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out, ease-in-out;

  &:disabled {
    pointer-events: none;
    background-color: $green-600;
    color: #fff;
  }

  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.btn-action-dark {
  background-color: $gray-800;
  color: #fff;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  box-sizing: border-box;
  transition-delay: 0s, 0s, 0s, 0s, 0s;
  transition-duration: .15s, .15s, .15s, .15s, .15s;
  transition-property: color, background-color, border-color, box-shadow, -webkit-box-shadow;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out, ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.btn-action-light {
  background-color: $gray-200;
  color: $gray-700;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  border-radius: .25rem;
  box-sizing: border-box;
  transition-delay: 0s, 0s, 0s, 0s, 0s;
  transition-duration: .15s, .15s, .15s, .15s, .15s;
  transition-property: color, background-color, border-color, box-shadow, -webkit-box-shadow;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out, ease-in-out;

  &:hover {
    background-color: $gray-300;
  }

}

.message-heading {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  line-height: 14px;
  vertical-align: top;
}

.message {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: 400;
  font-size: 13px;
  text-align: left;
  line-height: 14px;
  vertical-align: top;
}

.form-label {
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  text-align: right;
  line-height: 14px;
  vertical-align: top;
}

.form-control {
  display: inline-block;
  line-height: 14px;
  vertical-align: middle;
  height: 32px;
  font-size: 12px;

  &:disabled
  {
    background-color:$gray-100;
  }
}

.k-dropdown
{
  width: 100% !important;
}

.k-combobox 
{
  width: 100% !important;
}

.k-grid-content,
.k-grid-content-locked,
.k-pager-wrap {
    white-space: nowrap !important;
}

.label {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background: $light-default-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  padding: 4px 8px;
  border-radius: .25rem;
  color: $light-default-color;
}

.label-primary {
  color: $light-primary-color;
  background: $light-primary-bg;
}

.label-success {
  color: $light-success-color;
  background: $light-success-bg;
}

.label-info {
  color: $light-info-color;
  background: $light-info-bg;
}

.label-warning {
  color: $light-warning-color;
  background: $light-warning-bg;
}

.label-danger {
  color: $light-danger-color;
  background: $light-danger-bg;
}

.btn-lt-primary {
  color: $light-primary-color;
  background: $light-primary-bg;

  &:hover {
    color: #fff;
    background: $light-primary-color;
  }
}

.btn-lt-secondary {
  color: $light-default-color;
  background: $light-default-bg;

  &:hover {
    color: #fff;
    background: $light-default-color;
  }
}

.btn-lt-success {
  color: $light-success-color;
  background: $light-success-bg;

  &:hover {
    color: #fff;
    background: $light-success-color;
  }
}

.btn-lt-info {
  color: $light-info-color;
  background: $light-info-bg;

  &:hover {
    color: #fff;
    background: $light-info-color;
  }
}

.btn-lt-warning {
  color: $light-warning-color;
  background: $light-warning-bg;

  &:hover {
    color: #000;
    background: $light-warning-color;
  }
}

.btn-lt-danger {
  color: $light-danger-color;
  background: $light-danger-bg;

  &:hover {
    color: #fff;
    background: $light-danger-color;
  }
}

.btn-status-fail {
  background: #FFEEEE;
}

.btn-status-success {
  background: #F3F8E9;
}

// BUTTONS
.btn-icon {
  width: 35px;
  height: 35px;
  padding: 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-circle {
  border-radius: 50%;
}

// BADGE
.role-badge {
  font-weight: 500;
  line-height: 1.5;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;

  &.ClientAdmin {
    background: #EEF7F3;
    color: #00ABAB;
  }

  &.SupportAdmin {
    background: #FCEFC7;
    color: #7C5E10;
  }

  &.ClientUser {
    background: #E7EAEE;
    color: #68778D;
  }

  &.Approver {
    background: rgba(4, 204, 0, 0.1);
    color: #04CC00;
  }

  &.Preparer {
    background: rgba(0, 118, 168, 0.15);
    color: #0076A8;
  }

  &.Support {
    background: rgba(255, 138, 0, 0.15);
    color: #FF8A00;
  }

  &.InternalUser {
    background: rgba(134, 188, 37, 0.1);
    color: #86BC25;
  }

  &.ExternalUser {
    background: #DDEFE8;
    color: #00ABAB;
  }

  &.DeactivatedUser {
    background: #DBE0E6;
    color: #68778D;
  }
}


//TABS
.section-nav {
  border-bottom: 1px solid $gray-200;

  li {
    &.nav-item {
      padding: 0.375rem 0;
    }

    &:first-child {
      padding-left: 0px;
    }

    a {
      color: $gray-800;
      font-weight: 500;
    }

    &.active {
      border-bottom: 2px solid $primary;
      box-sizing: border-box;

      a {
        color: $primary;
      }
    }

    &.disabled {
      a {
        color: $gray-300;
        cursor: not-allowed
      }

    }

    &:hover {
      cursor: pointer;
    }
  }
}

// FLEX
.flex-1 {
  flex: 1
}


.grid-name-text {
  color: $primary;

  &:hover {
    font-weight: 500;
    cursor: pointer;
  }
}

// GRID STANDARDIZATION

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
}

.k-switch {
  font-size: 12px !important;
  height: 20px !important;
}

.k-switch-on .k-switch-container {
  background-color: $primary;
}

.k-switch-handle {
  height: 20px !important;
}

.Row {
  display: table;
  width: 100%;
  /*Optional*/
}

.Column {
  display: table-cell;
}

.center-block {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: block;
  width: 50px;
  background-color: $primary;
}

.middle-column {
  vertical-align: middle;
}

.first-column {
  width: 50%;
}

.action-column {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  float: center;
}

.Entity-Type-container {
  width: 220px;
  height: 98px;
  margin-top: 7%;
  border-radius: 4px;
  background: $gray-100;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, .1) 0 7px 14px 0, rgba(0, 0, 0, .07) 0 3px 6px 0;
  margin-left: 25px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-top: 10px;

  .btn {
    background-color: $primary;
    text-align: center;
    height: 30px;
    display: inline-block;
  }
}

.Entity-State-container {
  display: inline-block;
  font-size: 13px;
  text-align: left;
  line-height: 20px;
  vertical-align: middle;
}

.pop-up {
  border-radius: 20px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: transparent;
  box-shadow: rgba(50, 50, 93, .1) 0 7px 14px 0, rgba(0, 0, 0, .07) 0 3px 6px 0;

  .header {
    height: 26px;
    width: 100%;
    border-bottom: 1px solid $gray-100;
    vertical-align: middle;
    margin-left: 2px;
  }

  .action {
    padding-top: 15px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: right;
    float: right;
  }

  .form-block {
    padding-top: 15px;
    display: flex;
    margin-left: 2px;
  }
}

.right-align {
  justify-content: right;
  float: right;
}

.first-row {
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  border-top: 1px solid #E7EAEE;
  padding-top: 7px;

  div.form-group {
    flex: 1;
    margin: 0px 10px;

    &:last-child {
      margin-right: 0px;
    }

    &:first-child {
      margin-left: 0px;
    }
  }
}

.election-heading {
  color: #000;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
}

.election-sub-heading {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: block;
}

.election-message {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: block;
  text-align: justify;
}


.align-end {
  float: inline-end;
}

.form-line
{
  align-items: center;
  width: 100% !important;

  .end{
    float: inline-end;
    width: 100%;
  }
}

.form-check {
  display: flex;
  align-items: center;

  .form-check-input {
    width: 1.25rem;
    height: 1.25rem;

    &:checked {
      background-color: $primary;
      border-color: $primary;
      background-size: 60% 60%;
    }
  }

  .form-check-label {
    margin-left: 8px;
  }
}

.admin-grid-1 {
  height: calc(100vh - 300px)
}

.admin-grid {
  height: calc(100vh - 250px)
}

.admin-grid-ps {
  height: 390px
}

.form-check-custom
{
  padding-left: 0em;
}

.kpi {
  background: $gray-100;
  width: auto;
  height: 85px;
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-left: 3px;

  &:not(:first-child) {
     margin-left: 20px;
  }

  .kpi-icon,
  .fa-box-full,
  .fa-coins {
     width: 48px;
     height: 48px;
  }

  .kpi-info {
     margin-left: 5px;

     .kpi-label {
      color: #000;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      display: block;
      margin-bottom: 8px;
     }

     .kpi-value {
      color: #000;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      display: block;
      margin-bottom: 1px;
     }

     .btn {
      background-color: $primary;
      text-align: center;
      height: 30px;
      display: inline-block;
      width: 100%;
    }
  }
}

.k-icon-button,
.k-button.k-button-icon {
    padding: 0.375rem;
    margin-left: 2px;
    margin-right: 2px;
    width: calc( 1.5em + 0.75rem + 2px);
    height: calc( 1.5em + 0.75rem + 2px);
    gap: 0;
}

kendo-label > .k-label {
  margin: 5;
}

.k-dropdownlist
{
  min-width: 15rem;
}

.k-multiselect
{
  min-width: 15rem;
  max-width: 30rem;
  min-height: 0rem;
  max-height: 5rem;
}

.k-list-item.k-selected, .k-selected.k-list-optionlabel .k-button-solid-primary{
  background-color: #00a3e0 !important;
  border: 1px solid #00a3e0 !important;
  color: white;
}


.error-message{
  color: $red;
  font-size: 12px;
}
