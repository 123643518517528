
// COMMON
.no-padding {
  .k-popup {
    padding: 0px;
  }
}

.k-state-selected {
  background-color: #00a3e0 !important;
  border: 1px solid #00a3e0 !important;
}


// FORMS

.k-checkbox:checked+.k-checkbox-label:before {
  border-color: $primary;
  background-color: $primary;
}

.k-checkbox-label {
  &:after {
    font-size: 12px !important;
    margin-top: 1px;
  }
}

.k-checkbox:indeterminate+.k-checkbox-label:after {
  border-color: $primary;
  background-color: $primary;
}

.k-checkbox:focus+.k-checkbox-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #00a3e040;
}




// GRID COMPONENTS
.k-grid {
  .k-grid-header .k-grid-filter {
    bottom: calc(0.375em);
  }

  .k-grid-header .k-grid-filter,
  .k-grid-header .k-header-column-menu {
    .k-icon {
      font-size: 12px;
      height: 14px;
    }
  }

  tbody {
    >.k-state-selected {
      background-color: #00a3e020 !important;
      border: 1px solid #00a3e030 !important;
    }
  }

  .k-pager-wrap .k-link,
  .k-pager-wrap .k-pager-nav {
    width: 50px;
    height: 35px;
    margin: 3px;
    border-radius: 4px;
    color: $gray-700;
  }

  .k-pager-wrap .k-state-selected {
    color: $white;
  }
}


.k-grid-header {
  .k-grid-filter {
    bottom: calc(.55rem + .75em + -.375rem + -.75em);
  }
}


// CALENDAR

kendo-datepicker {
  .k-state-focused {
    .k-input:focus {
      border-color: #00a3e0;
    }
  }
}

kendo-calendar.k-calendar {
  .k-calendar-navigation {
    .k-calendar-navigation-highlight {
      font-weight: 700;
      color: #00a3e0;
    }
  }

  .k-calendar-view {
    .k-calendar-header {
      .k-today {
        color: #00a3e0;
      }
    }

    .k-content {

      .k-alt {
        background: #F2F2F5;
        padding: 4px;

        .weeknumber-cell {
          color: #77879c
        }
      }
    }
  }

  .k-month {
    td {
      width: 12px;
    }
  }

  .k-calendar-monthview {
    td {
      .k-link {
        width: 30px;
        height: 32px;
      }

      &.k-state-focused {
        .k-link {
          background: #00a3e0;
          box-shadow: inset 0 0 0 1px #00a3e0;
          color: #fff;
        }
      }
    }

  }
}

.k-calendar-monthview .k-content .k-link,
.k-calendar-monthview .k-content td,
.k-calendar-view .k-content .k-link,
.k-calendar-view .k-content td {
  border-radius: 0;
}


// NOTIFICATIONS 
.k-notification-group {
  z-index: 99999;

  .k-notification-container {
    margin: .375rem;
  }

  .k-notification {
    min-width: 275px;
    max-width: 400px;

    .k-notification-wrap {
      padding: 1.5rem 1.25rem;
    }
  }
}

form-check .form-check-input {
  float: left;
  margin-left: -2.25rem
}

.form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -.125rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  appearance: none;
  print-color-adjust: exact;
  transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .form-check-input {
    transition: none
  }
}

.form-check-input[type=checkbox] {
  border-radius: .45em
}

.form-check-input[type=radio] {
  border-radius: 50%
}

.form-check-input:active {
  filter: brightness(90%)
}

.form-check-input:focus {
  border-color: #b5b5c3;
  outline: 0;
  box-shadow: none
}

.form-check-input:checked {
  background-color: #009ef7;
  border-color: #009ef7
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'%3e%3cpath d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='%23FFFFFF'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #009ef7;
  border-color: #009ef7;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
  opacity: .5
}

.form-switch {
  padding-left: 3.75rem
}

.form-switch .form-check-input {
  width: 3.25rem;
  margin-left: -3.75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 3.25rem;
  transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .form-switch .form-check-input {
    transition: none
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23B5B5C3'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e")
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none
}

.btn-check:disabled+.btn,
.btn-check[disabled]+.btn {
  pointer-events: none;
  filter: none;
  opacity: .6
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none
}

.form-range:focus {
  outline: 0
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem rgba(0, 158, 247, .25)
}

.k-dialog-titlebar {
  color: #212529;
  background-color: #ffffff;
}
