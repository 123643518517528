body {
    display: flex;
    font-family: 'Open Sans', sans-serif;
    font-size: 15px!important;
 }
 
 .app-root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
 
    .app-content {
       flex-grow: 1;
       position: relative;
 
    }
 }
 
 .grid-container {
    background: white;
    padding: 15px;
    box-shadow: 3px 1px 4px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid #DDE2E7;
 }
 
 
 .election-summary {
   padding: 1rem;
   background-color: $white;
   border-radius: 10px;
   margin: 12px 0;
   box-shadow: rgba(50, 50, 93, .1) 0 7px 14px 0, rgba(0, 0, 0, .07) 0 3px 6px 0;
   margin-bottom: 5rem;
}

.election-summary-plain {
   margin: 17px 0;
   margin-bottom: 5rem;
}

.element-wrapper
{
   display: flex;
   flex-wrap: wrap;
}